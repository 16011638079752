import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import Img from "gatsby-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faLightbulb,
    faPaperPlane,
  } from "@fortawesome/free-solid-svg-icons";

import Layout from '../components/layout'
import SEO from '../components/seo'
import aboutStyles from './about.module.scss'

const AboutPage = () => {
    const data = useStaticQuery(graphql`
        query {
            aikido: file(relativePath: { eq: "images/aikido.png" }) {
                childImageSharp {
                    fluid(maxWidth:1000) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            iaido: file(relativePath: { eq: "images/iaido.jpg" }) {
                childImageSharp {
                    fluid(maxWidth:1000) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            jujitsu: file(relativePath: { eq: "images/taiso.jpg" }) {
                childImageSharp {
                    fluid(maxWidth:1000) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            jo: file(relativePath: { eq: "images/jodo.jpg" }) {
                childImageSharp {
                    fluid(maxWidth:1000) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            sofu: file(relativePath: { eq: "images/sofu.jpg" }) {
                childImageSharp {
                    fluid(maxWidth:1000) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            shuriken: file(relativePath: { eq: "images/shuriken.jpg" }) {
                childImageSharp {
                    fluid(maxWidth:1000) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `)

    return (
        <Layout>
            <SEO
                pagetitle="About"
                pageDesc="Aiki Ishin-ryu is a style of martial art. More information about Aiki Ishin-ryu are described in details."
            />
            <h1>About Aiki Ishin-ryu</h1>
            <p>
                Aiki Ishin-ryu is a martial art style founded by Yoshinaka Suzuki, who used to practice in Takeda-ryu Nakamura-ha for a long time.
                Our training includes Aikido, Iaido, Jujutsu Kenpo, Jo-jutsu, Sofu-jutsu and Shuriken-jutsu, inspired by Takeda-ryu and Takeda-ryu Nakamura-ha.
                The uniqueness of our style, as shown in our name, is in the use of "Aiki no Jutsu" (相氣之術: art of "Aiki" different from the famous 合気), which was historically used in Ken-jutsu (traditional art of Japanese sword).
            </p>

            <nav className={aboutStyles.index}>
                <h2>Contents</h2>
                <ol>
                    <Link to ="/about#our-concept"><li>
                        Our Concept
                    </li></Link>
                    <Link to ="/about#headquarter-dojo"><li>
                        Headquarter Dojo
                    </li></Link>
                    <Link to ="/about#six-jutsu"><li>
                        6 Jutsu
                    </li></Link>
                    <Link to ="/about#grades"><li>
                        Grades: Kyu and Dan
                    </li></Link>
                    <Link to ="/about#qa"><li>
                        Frequently Asked Questions
                    </li></Link>
                </ol>
            </nav>

            <a className={aboutStyles.anchor} id="our-concept"></a>
            <h2 className={aboutStyles.sectionTitle}>Our Concept</h2>
            <p>
                Aiki Ishin-ryu was founded to focus fully on the education, of the mindset to face oneself and opponents, of the mentality with a stable core, and of the personality that empowers oneself and surrounding people as humanity.
                All of our trainings and subordinate systems are designed to fulfill this concept.
            </p>

            <a className={aboutStyles.anchor} id="headquarter-dojo"></a>
            <h2 className={aboutStyles.sectionTitle}>Headquarter Dojo</h2>
            <p>
                The headquarter of Aiki Ishin-ryu is located in Tokyo, Japan. (See the map below.)
                
            </p>
            <div className={aboutStyles.map}>
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d597.8046491700427!2d139.6785527532463!3d35.74290804995763!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6018ed38793779b1%3A0xe91692056e5dc964!2zTlBP5rOV5Lq644Kk44Oz44K_44O844OK44K344On44OK44Or5ZCI5rCX6YGT5Y2U5Lya!5e0!3m2!1sen!2sjp!4v1600533977698!5m2!1sen!2sjp"
                    allowFullScreen
                    aria-hidden="false"
                    title="Location of Japan Headquarter Dojo"
                    width="450"
                    height="300"
                />
            </div>
            <h4>Planning to visit?</h4>
            <h4> <Link to="/contact" className={aboutStyles.contact}>
                <FontAwesomeIcon
                    icon={faPaperPlane}
                    style={{ color: "#333" }}
                />
                &nbsp;
                Take an Appointment!
            </Link></h4>

            <a className={aboutStyles.anchor} id="six-jutsu"></a>
            <h2 className={aboutStyles.sectionTitle}>6 Jutsu</h2>
            <p>The six arts of Aiki Ishin-ryu has the following features.</p>
            <div className={aboutStyles.section}>
                <ul className={aboutStyles.jutsu}>
                    <li>
                        <div className={aboutStyles.image}>
                            <Img fluid={data.aikido.childImageSharp.fluid} />
                        </div>
                        <div className={aboutStyles.jitsuDetail}>
                            <h4>Aikido (合気道)</h4>
                            <p>
                                Aikido is a type of Jujutsu which uses "Aikid no Jutsu".
                                The Aikido of Aiki Ishin-ryu has 36 fundermental techniques comprising Atemi-waza (当身技: striking), Kote-waza (甲手技: hand technique), Koshi-waza (腰技: waist technique), Ne-waza (寝技: ground works) and so on.
                                Students will learn those fundermentals from early stages and move on to the more advanced techniques later.
                                This method of training was established to enable faster understandings of common contexts behind all the techniques.
                            </p>
                        </div>
                        
                    </li>
                    <li>
                        <div className={aboutStyles.image}>
                            <Img fluid={data.iaido.childImageSharp.fluid} />
                        </div>
                        <div className={aboutStyles.jitsuDetail}>
                            <h4>Iaido (居合道)</h4>
                            <p>
                                Iaido is the training of Batto-jitsu (抜刀術), the art of drawing Katana (Japanese traditional sword) from Saya (sheath).
                                By studying and understanding Katana and Iaido, students can not only learn the background of "Aiki no Jutsu" but also experience the history of Japan.
                                Since Katana played significant roles in religious events, Iaido is excellent for learning Japanese traditions.
                                Therefore, students are required to both perform the techniques well and have the right knowledge of each moves and items.
                            </p>
                        </div>
                    </li>
                    <li>
                        <div className={aboutStyles.image}>
                            <Img fluid={data.jujitsu.childImageSharp.fluid} />
                        </div>
                        <div className={aboutStyles.jitsuDetail}>
                            <h4>Jujutsu Kenpo (柔術拳法)</h4>
                            <p>
                                Historically, Japanese traditional martial arts have Atemi (striking) but those are different from punches in early ages.
                                In order to compensate this lack of techniques, Jujutsu Kenpo was invented.
                                The trainings of Jujutsu Kenpo includes punches, kicks and blocks.
                                Despite its birth being relatively late, the techniques of Jujutsu Kenpo has unique moves that came from riding horses during battles.
                            </p>
                        </div>
                    </li>
                    <li>
                        <div className={aboutStyles.image}>
                            <Img fluid={data.jo.childImageSharp.fluid} />
                        </div>
                        <div className={aboutStyles.jitsuDetail}>
                            <h4>Jo-jutsu (杖術)</h4>
                            <p>
                                Jo-jutsu is the art of fighting with canes in the original meaning, so sticks are commonly used to train this art.
                                However, Jo-jutsu in Aiki Ishin-ryu comes from fighting with the sheath in case of emergency.
                                That's why our Jo sticks are 3 Shaku (尺: 1 Jaku = 30.3cm = 0.994ft) long, which is about the same length of the sheath of Katana.
                                In addition to strinking, our trainings of Jo-jutsu include Nage-waza (投げ技: throwing opponents) which can only practiced after the student has learnt the basics of Aikido and Iaido.
                            </p>
                        </div>
                    </li>
                    <li>
                        <div className={aboutStyles.image}>
                            <Img fluid={data.sofu.childImageSharp.fluid} />
                        </div>
                        <div className={aboutStyles.jitsuDetail}>
                            <h4>Sofu-jutsu (双桴術)</h4>
                            <p>
                                Sofu-jutsu is the art of fighting with two sticks of 1.5 Shaku (尺: 1 Jaku = 30.3cm = 0.994ft) long.
                                It was systematized originally by Yoshinaka Suzuki with various insipirations from Shugi-jutsu (手木術), Tanbo-jutsu (短棒術), Hanbo-jutsu (半棒術) and others.
                                This art comprises 3 basic Kata (型: sets of movements in a flow) and 2 secret Kata.
                            </p>
                        </div>
                    </li>
                    <li>
                        <div className={aboutStyles.image}>
                            <Img fluid={data.shuriken.childImageSharp.fluid} />
                        </div>
                        <div className={aboutStyles.jitsuDetail}>
                            <h4>Shuriken-jutsu (手裏剣術)</h4>
                            <p>
                                Shuriken-jutsu is the art of throwing Shuriken (手裏剣: dagger behind hand).
                                Usually, the trainings involves throwing Bo-Shuriken (stick type Shuriken) from various distances and angles.
                                Ordinally, only students with Dan grades practice this art.
                            </p>
                        </div>
                    </li>
                </ul>
            </div>
            
            <p>
                <FontAwesomeIcon
                    icon={faLightbulb}
                    style={{ color: "#333" }}
                />
                &nbsp;
                Trivia: Our training of Jujutsu is also influenced by Brazilian Jiu-Jitsu, which Soke found useful when he once trained in Brazil.
            </p>
            <h4>Interested?</h4>
            <h4><Link to="/contact" className={aboutStyles.contact}>
                <FontAwesomeIcon
                    icon={faPaperPlane}
                    style={{ color: "#333" }}
                />
                &nbsp;
                Join us here!
            </Link></h4>

            <a className={aboutStyles.anchor} id="grades"></a>
            <h2 className={aboutStyles.sectionTitle}>Grades: Kyu and Dan</h2>
            <p>
                Aiki Ishin-ryu has grades in Aikido and Iaido.
                When one becomes a member of Aiki Ishin-ryu, he/she will be referred as Nyumon-sha (入門者: beginner).
                Then, after taking exams and proving one's ability and knowledge, Soke will give him/her grades beginning from 8th to 1st Kyu (級) with colored belts, and then from 1st to 5th Dan (段) with black belts.
                The contents of the exams are strictly specified by Soke and is common worldwide, regardless of examinee's age, gender, nationality or religious belief.
                Therefore, no one can skip the exams to obtain the grades even if the one is already skilled in other martial arts.
                The list below is the overview of trainings required to obtain those grades.
            </p>
            <ul>
                <li>
                    <h4>8th ~ 1st Kyu, 1st Dan</h4>
                    <p>
                        Students practice basics of Jujutsu through the fundermental 36 techniques of Aikido and Jujutsu Kenpo.
                        As for training the use of weapons, studnets learn basic movements and self-defense techniques of Iaido.
                        The main goal is to get used to the unique movements, to build the sufficient body, and to learn how to self-defense.
                    </p>
                </li>
                <li>
                    <h4>2nd Dan</h4>
                    <p>
                        In addition to the techniques below 1st Dan, students practice 1st Dan level Kaeshi-waza (返し技: counter attacks), "Kihon Aiki no Waza" (基本相氣の技: the core techniques of Aiki), advanced ground works, Shuriken-jutsu, Iaido, and Bokken (木剣: wooden sword).
                        The trainings also inlude the techniques against two opponents.
                        Students will also be given a chance to learn 1st Dan level of the hidden techniques to widen the capacity of his/her techniques and must start thinking about the meaning of "Aiki".
                    </p>
                </li>
                <li>
                    <h4>3rd Dan</h4>
                    <p>
                        In addition to the techniques below 2nd Dan, students practice the altered techniques and the techniques with the use of Keiketsu (経穴: acupuncture points) or Keiro (経路: acupuncture lines).
                        Students must also practice Jo-jutsu and techniques against multiple opponents.
                    </p>
                </li>
                <li>
                    <h4>4th Dan</h4>
                    <p>
                        Students train the overall techniques of "Aiki".
                        The trainings include Sofu-jutsu.
                    </p>
                </li>
                <li>
                    <h4>5th Dan and Above</h4>
                    <p>
                        The trainings on Okuden, Hiden (奥伝, 秘伝: secret teachings) is added.
                    </p>
                </li>
            </ul>
            <p>
                <FontAwesomeIcon
                    icon={faLightbulb}
                    style={{ color: "#333" }}
                />
                &nbsp;
                Trivia: Soke has no grade because he is the founder of this system and his grade would practically mean nothing.
            </p>
            <h4>Ready to step up?</h4>
            <h4><Link to="/contact" className={aboutStyles.contact}>
                <FontAwesomeIcon
                    icon={faPaperPlane}
                    style={{ color: "#333" }}
                />
                &nbsp;
                Join us here!
            </Link></h4>

            <a className={aboutStyles.anchor} id="qa"></a>
            <h2 className={aboutStyles.sectionTitle}>Frequently Asked Questions</h2>
            <ul>
                <li>
                    <h4>Is it possible to become a member of Aiki Ishin-ryu from outside of Japan?</h4>
                    <p>
                        Yes, it is possible if you invite Soke.
                        Fortunately, it may be much easier in the future if someone near you is Soke's proxy. 
                        However, speaking of the current situation, there is yet no member abroad with the right to become the proxy, so you need to either wait or meet Soke somehow.
                    </p>
                </li>
                <li>
                    <h4>I want to visit Japan to meet Soke for the 1st time. Can Aiki Ishin-ryu help me get the visa?</h4>
                    <p>
                        Unfortunately, no.
                        Our activity in Japan is supervised by Tokyo Metropolitan Government and we must make sure only trustworthy person get the visa.
                        Since we cannot take responsibility of a person with whom we have never met, you need to make your own way for the 1st time.
                        On the other hand, if you manage to prove your trustworthiness such as a guarantee from the proxy of Soke, there may be a chance.
                    </p>
                </li>
                <li>
                    <h4>How can I become a teacher of Aiki Ishin-ryu?</h4>
                    <p>
                        To teach the techniques of Aiki Ishin-ryu, one must be given an valid license from Soke.
                        Only the members of Aiki Ishin-ryu with 5th Dan of both Aikido and Iaido have the right to ask Soke for the license, but the limited version is availabe to those with 3rd Dan.
                        For reference, a typical Japanese member who trains in Dojo several times a week will require more than 10 years to get the 5th Dan.
                        Thus, the fastest way to start teaching Aiki Ishin-ryu would be to stay in Japan and attend to all the 17 classes in a week for several years.
                    </p>
                </li>
            </ul>
            <h4>Cannot find what you need?</h4>
            <h4><Link to="/contact" className={aboutStyles.contact}>
                <FontAwesomeIcon
                    icon={faPaperPlane}
                    style={{ color: "#333" }}
                />
                &nbsp;
                Ask here!
            </Link></h4>
        </Layout>
    )
}

export default AboutPage